import React, { Fragment, useEffect, useState, useCallback } from "react"
import * as styles from "./detail.module.css"
import queryString from "query-string"
import Layout from "components/common/layout.js"
import api from "apis"
import SlidePagination from "components/tools/slide/slidePagination.js"
import {
  getDateYMD,
  textEdit,
  titleEdit,
  getThumb,
  getEpisode,
  getRawThumb,
} from "utils"
import Story from "components/story/story"
import Slider from "components/tools/slide/Slide.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getMonthListAsync } from "store/modules/postReducer.js"
import Month from "components/post/month"
import { navigate } from "gatsby"
import dompurify from "dompurify"
const detail = props => {
  const { book_no } = queryString.parse(props.location.search)
  const [scrollImg, setScrollImg] = useState([])
  const [slideList, setSlideList] = useState([])
  const [post, setPost] = useState({})

  const { monthList, monthTotal } = useSelector(
    ({ postReducer: { month } }) => month,
    shallowEqual
  )

  const dispatch = useDispatch()

  const getMonthList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getMonthListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    ;(async () => {
      const result = await api.getMonthDetail(book_no)
      if (result) {
        setScrollImg(result.BookPage)
        //setSlideList(result.BookContent)  해당 컨텐츠 넘버와 북넘버를 통해 연관스토리 조회필요함
        const list = []
        await Promise.all(
          result.BookContent.map(async content => {
            const result = await api.MonthAssociatedStory(
              book_no,
              content.content_no
            )
            list.unshift({
              no: content.content_no,
              desc: content.desc,
              title: content.title,
              list: result,
            })
          })
        )

        list.sort((a, b) => a.no - b.no)
        setSlideList(list)

        setPost({
          // no: result.book_no,
          title: result.title,
          content: result.introText,
          pub: result.pubText, // 발행일
          subtitle: result.subtitle,
        })
      }
    })()
  }, [book_no])

  return (
    <Layout>
      {/* <div className={styles.scroll}>
        {scrollImg.map(v => (
          <img src={getThumb(v.thumb_page_uuid)} key={v.page_no} />
        ))}
      </div> */}
      <div className={styles.scroll}>
        <div className={styles.left}></div>
        <div className={styles.right}></div>
        <Slider name={"monthlyPeach"} length={scrollImg.length}>
          {scrollImg.map(v => (
            <div style={{ width: "750px", height: "530px" }}>
              <img src={getThumb(v.thumb_page_uuid)} key={v.page_no} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.post}>
        <div>{post.subtitle}</div>
        <div>{post.title}</div>
        {typeof window !== "undefined" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(post.content),
            }}
          />
        ) : null}
        <div>{post.pub}</div>
      </div>
      <hr />
      <div className={styles.asso}>
        {slideList.map((list, i, { length }) => {
          const array = list.list
          return (
            <Fragment key={i}>
              <div className={styles.assoItem}>
                <div>
                  <div>{list.title}</div>
                  <div>{list.desc}</div>
                </div>
                <div className={styles.slideCon}>
                  <Slider name={"oneHandle"} length={array.length}>
                    {array.map(post => (
                      <Fragment key={post.story_no}>
                        <Story
                          registerTime={post.publishTime}
                          story_no={post.story_no}
                          title={post.title}
                          introText={post.introText}
                          thumb_title_uuid={post.thumb_title_uuid}
                          cateMain={post.cateMain}
                          cateSub={post.cateSub}
                          Like={post.Like}
                          free={post.free}
                        />
                      </Fragment>
                    ))}
                  </Slider>
                </div>
              </div>
              {i !== length - 1 ? <div className={styles.dott} /> : ""}
            </Fragment>
          )
        })}
      </div>

      <SlidePagination
        total={monthTotal}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        postSize={4}
        getlist={getMonthList}
        viewAll={() => {
          navigate("/monthlyPeach/list")
        }}
        title={"최근 월간 피치서가 보기"}
        btn={"circle"}
        idxOff={true}
      >
        <div className={styles.pageSlideCon}>
          {monthList.map(post => (
            <Month post={post} key={post.book_no} />
          ))}
        </div>
      </SlidePagination>
    </Layout>
  )
}
export default detail
