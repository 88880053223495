import React from "react"
import * as styles from "./month.module.css"
import { getDateYMD, getThumb } from "utils"
import { navigate } from "gatsby-link"

const Month = ({ post }) => {
  return (
    <div
      onClick={() => {
        navigate(`/monthlyPeach/detail?book_no=${post.book_no}`)
      }}
      style={{
        cursor: "pointer",
      }}
      className={styles.month}
    >
      <img src={getThumb(post.thumb_title_uuid)} />
      <div className={styles.subtitle}>{post.subtitle}</div>
      <div className={styles.title}>{post.title}</div>
      <div>{post.pubText}</div>
    </div>
  )
}

export default Month
