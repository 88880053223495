// extracted by mini-css-extract-plugin
export var scroll = "detail-module--scroll--2O_KT";
export var left = "detail-module--left--2qgvI";
export var right = "detail-module--right--1y6XF";
export var post = "detail-module--post--3kr-z";
export var asso = "detail-module--asso--33uol";
export var assoItem = "detail-module--assoItem--ESaWH";
export var slideCon = "detail-module--slideCon--2Xbl8";
export var headerSlideCon = "detail-module--headerSlideCon--3orF3";
export var dott = "detail-module--dott--1gQil";
export var pageSlideCon = "detail-module--pageSlideCon--igN6l";